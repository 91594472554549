@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, white, galleryDark from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .sizeFilterContents {
    overflow-y: auto;

    @media breakpointLgUp {
      max-height: 500px;
      padding: 0;
    }
  }

  .sizeButtonsContainer {
    width: 100%;
    padding: md md md calc(md + (md * var(--depth)));
    display: flex;
    flex-wrap: wrap;
    gap: xs;
  }

  .sizeButton {
    height: calc(xl + sm + xs);
    width: calc(xl + sm + xs);
    padding: 0;
    border: 1px solid black;
    border-radius: borderRadiusMedium;
    background-color: transparent;
    cursor: pointer;
    font-size: 10px;

    &:hover {
      background-color: galleryDark;
    }
  }

  .sizeButtonText {
    font-size: 10px;
    color: inherit;
  }

  .sizeButtonSelected {
    background-color: black;

    &:hover {
      background-color: black;
      opacity: 0.6;
    }

    color: white;
  }

  .collapsibleRow {
    width: 100%;
    height: calc(xl + md);
    margin: 0;
    padding: md md md calc(md + (md * var(--depth)));
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-top: 1px solid galleryDark;

    &:hover {
      background-color: galleryDark;
    }

    @media breakpointLgUp {
      &:first-of-type {
        border-top: none;
      }
    }
  }
}
