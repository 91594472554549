@layer components {
  .styles_sizeFilterContents__fA2Bh {
    overflow-y: auto;
  }

    @media (min-width: calc(1024 * 1px)) {
  .styles_sizeFilterContents__fA2Bh {
      max-height: 500px;
      padding: 0
  }
    }

  .styles_sizeButtonsContainer__KhE5q {
    width: 100%;
    padding: 16px 16px 16px calc(16px + (16px * var(--depth)));
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .styles_sizeButton__1vfsv {
    height: calc(32px + 8px + 4px);
    width: calc(32px + 8px + 4px);
    padding: 0;
    border: 1px solid #262626;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    font-size: 0.625rem;
  }

    .styles_sizeButton__1vfsv:hover {
      background-color: #EAEAEA;
    }

  .styles_sizeButtonText__HS9Zv {
    font-size: 0.625rem;
    color: inherit;
  }

  .styles_sizeButtonSelected__rru_l {
    background-color: #262626;
  }

    .styles_sizeButtonSelected__rru_l:hover {
      background-color: #262626;
      opacity: 0.6;
    }

    .styles_sizeButtonSelected__rru_l{

    color: #FFFFFF;
  }

  .styles_collapsibleRow__tVnDg {
    width: 100%;
    height: calc(32px + 16px);
    margin: 0;
    padding: 16px 16px 16px calc(16px + (16px * var(--depth)));
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-top: 1px solid #EAEAEA;
  }

    .styles_collapsibleRow__tVnDg:hover {
      background-color: #EAEAEA;
    }

    @media (min-width: calc(1024 * 1px)) {
      .styles_collapsibleRow__tVnDg:first-of-type {
        border-top: none;
      }
    }
}

